<template>
  <v-app>
    <div class="ma-5" v-if="this.term">
      <b>{{ $t("利用規約") }}</b
      ><br />
      <p v-html="this.term.translated[this.$i18n.locale]"></p>
      <b>{{ $t("プライバシーポリシー") }}</b
      ><br />
      <p v-html="this.privacy.translated[this.$i18n.locale]"></p>
    </div>
  </v-app>
</template>

<script>
import { Firebase } from "../config/firebase";
export default {
  name: "Rule",
  data() {
    return {
      loading: true,
      showPassword: false,
      alert: false,
      checkbox: false,
      login_view: false,
      password: "",
      name: "",
      phone: "",
      user: {},
      error_message: "",
      term: {},
      privacy: {},
      common: null,
    };
  },
  components: {},
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created: function () {
    this.commonFetchById();
  },
  methods: {
    commonFetchById: async function () {
      const privacyRef = await Firebase.firestore()
        .collection("translations")
        .doc("Privacy");
      privacyRef.get().then((doc) => {
        if (doc.exists) {
          this.privacy = doc.data();
        } else {
          // doc.data() will be undefined in this case
          console.log("No privacy document!");
        }
        this.error = true;
      });
      const termRef = await Firebase.firestore()
        .collection("translations")
        .doc("Term");
      termRef.get().then((doc) => {
        if (doc.exists) {
          this.term = doc.data();
        } else {
          // doc.data() will be undefined in this case
          console.log("No term document!");
        }
        this.error = true;
      });

      this.loading = false;
    },
  },
};
</script>
